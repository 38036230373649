import { createApp } from 'vue';

import App from './App.vue';
import './registerServiceWorker';

import router from './router';
import { i18nInstance } from './composables/useLocale';
import { GlobalComponents } from "@/plugins/GlobalComponens";
import { Devices } from "@/composables/useBreakpoints";
import { NCALayer } from "@/composables/useNcaLayer";
import { Socket } from '@/composables/useSocket';
import Notifications from '@kyvg/vue3-notification';
import UserStorePlugin from "@/plugins/UserStorePlugin";
import { StoreInspector } from '@/store/store.devtools';


import './assets/scss/main.scss';
// import 'v-calendar/dist/style.css';

let app = createApp(App)
    .use(i18nInstance)
    .use(UserStorePlugin)
    .use(router)
    .use(Notifications)
    .use(StoreInspector)
    .use(GlobalComponents)
    .use(Devices)
    .use(Socket);

if(process.env.NODE_ENV === 'production') {
    app.use(NCALayer)
}
    // .component('FontAwesomeIcon', FontAwesomeIcon)

app.mount('#app');
